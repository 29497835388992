$white: #ffffff;
$black: #191a33;
$lightGray: #979797;
$gray: #43434a;
$darkGray: #534c46;
$darkGray2: #231d19;
$darkGray3: #252220;
$darkGray4: #605751;
$textGray: #6b6b6b;
$lightGray: #d8d8d8;
$textDarkBlue: #2b374a;
$orange: #ec7000;
$purple: #531e6d; // ou #781B8E
$blue: #007ab7;
$stripeGray: #f3f3f3;

/* --------------------------- */

$linkColor: #106eb0;
$background-selected-color: #faf8f7;
$background-color: #efe9e5;
$background-color: #f7f4f2;
$background-bullet-color: #d8d8d8;
$border-gray: #e5e5e5;
$border-dark-gray: #efe9e5;

$background-odd-section: #fafafa;
$background-section: #f6f6f6;

$disabled-color: #666;

/* --------------------------- */
/* THEME
/* --------------------------- */

$primaryTransparent: rgba(236, 112, 0, 0.9);
