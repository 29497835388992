@import './_mixins.scss';
@import './_itaucolors.scss';

html {
  font-size: 62.5%;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-family: 'ItauText-Bold';
}

b {
  font-family: 'ItauText-Bold';
}

*,
::after,
::before {
  box-sizing: border-box;
}

button {
  border: 0;
  padding: 0;
  background: none;
}

#root {
  height: 100%;
}

.no-scroll {
  overflow: auto !important;
}

a,
[role='link'] {
  text-decoration: underline;
  font-size: 16px;
  color: $linkColor;
  font-family: 'ItauText-Bold';
}

.content-wrapper {
  max-height: 100vh;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}


.content {
  min-height: calc(100vh - 60px);

  @include mq('tablet-wide') {
    min-height: calc(100vh - 226px);
  }
}

.container {
  flex: 1;
  display: flex;
  width: 90%;
  margin: 0 auto;

  @include mq('desktop') {
    max-width: 1104px;
  }
}

.gray {
  background-color: #f7f7f7;
}

.margin-container {
  margin: 0 calc(100vw - 95%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  border: inherit;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.js-focus-visible :focus:not(.focus-visible):not([data-focus-visible-added]) {
  outline: none;
}

.text-visually-hidden {
  display: block;
  height: 0;
  color: transparent;
  position: absolute;
}
strong {
  font-family: ItauText-XBold;
  font-weight: 500;
}