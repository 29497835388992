@import '../../styles/_itaucolors.scss';

.IconButton {
    border: none;
    background-color: transparent;
    display: inline-block;

    i {
        font-size: 24px;
        color: $orange;
    }
}