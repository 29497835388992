@import '../../styles/_itaucolors.scss';
@import '../../styles/_mixins';

.Modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1300;
  display: flex;
  align-items: center;

  .backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #0b0b0b;
    opacity: 0.7;
    z-index: 999;
    top: 0;
    left: 0;
    overflow: auto;
    transition: visibility 0.3s linear;
  }

  .modal {
    background-color: $white;
    width: calc(100vw - 32px);
    max-width: 800px;
    margin: auto 16px;
    z-index: 999;
    padding: 48px 0;
    position: relative;
    overflow: auto;
    transition: visibility 0.3s linear;
    animation: slide-in-up 0.6s ease forwards;

    @include mq('tablet-wide') {
      max-width: 52vw;
      margin: auto auto;
    }

    .modal-content {
      max-height: calc(80vh - 96px);
      -webkit-overflow-scrolling: touch;
      padding: 0 16px;
      
      @include mq('tablet') {
        padding: 0 40px;
        max-height: 80vh;
      }
    }
  }

  .IconButton {
    position: absolute;
    top: 8px;
    right: 0;
    cursor: pointer;

    @include mq('tablet') {
      right: 8px;
    }

    i {
      font-size: 32px;

      @include mq('tablet') {
        font-size: 40px;
      }
    }
  }

  .Snackbar {
    top: unset;
    left: unset;
    bottom: unset;
    right: unset;
    margin-top: 24px;
    margin-bottom: 60px;
    position: relative;
    justify-content: center !important;
    @include mq('desktop-ultra') {
      margin-left: 20px;
    }

    button {
      box-sizing: unset !important;
    }
    .SnackbarContent {
      max-width: unset !important;
      width: 100% !important;
    }
  }
}

.Modal.full-screen {
  .modal {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    max-width: 100%;

    .modal-content {
      max-height: 100%;

      @include mq('tablet-wide') {
        max-height: 80vh;
      }
    }

    @include mq('tablet-wide') {
      max-width: 52vw;
      padding: 48px 0;
      margin: auto auto;
      height: auto;
    }
  }
}

.Modal.exit {
  .modal {
    animation: slide-in-down 0.6s ease forwards;
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(200%);
    -ms-transform: translateY(200%);
    -webkit-transform: translateY(200%);
    -moz-transform: translateY(200%);
    -o-transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
@keyframes slide-in-down {
  0% {
    transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
  }
  100% {
    transform: translateY(220%);
    -ms-transform: translateY(220%);
    -webkit-transform: translateY(220%);
    -moz-transform: translateY(220%);
    -o-transform: translateY(220%);
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Modal .Snackbar {
    bottom: 46px;
  }
}
