@import './_itaucolors.scss';

// COMPONENTS
// Header
$header-height-mobile: 65px;
$header-link-spacing: 48px;
$header-height-desktop: 80px;
$header-border-width: 3px;

// Coverage
$coverage-card-content-mobile: 108px;
$coverage-card-content-desktop: 158px;
$coverage-card-image-mobile: 52px;
$coverage-card-image-desktop: 82px;
$coverage-card-checkbox-desktop: 40px;

// Card
$card-spacing: 16px;
$card-background-color: $white;
$card-border-radius: 8px;
$card-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.18);
$card-border: solid 1px rgba(0, 0, 0, 0.16);
$card-border-hover: solid 1px $orange;

// Button
$button-font-family: 'ItauText-Bold';
$button-border-radius: 4px;
$button-small-pd-mobile-up: 0 24px;
$button-small-pd-tablet-up: 0 48px;

$button-medium-pd-mobile-up: 0 40px;
$button-medium-pd-tablet-up: 0 64px;

$button-large-pd-mobile-up: 0 40px;
$button-large-pd-tablet-up: 0 80px;

$button-bg-disabled: #d9d3cf;
$button-color-disabled: #969290;
