@import '../../styles/_mixins';
@import '../../styles/_itaucolors.scss';

.NumericalSlider {
    position: relative;
    input {
        &[type=range]{
            -webkit-appearance: none;
            background-color: transparent;
            position: absolute;
            height: 45px;
            width: 101%;
            z-index: 1000;

            @include mq('tablet') {
                height: 32px;
            }
        }
        &[type=range]::-webkit-slider-runnable-track {
            background-color: transparent;
            border: none;
        }
        
        &[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: none;
            height: 0.875rem;
            width: 0.875rem;
            border-radius: 50%;
            background: $orange;
            cursor: pointer;
            z-index: 1000;
        }
    }
    
}
