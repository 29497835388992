@font-face {
  font-family: 'ItauText-Light';
  src: local('ItauText-Light'), url(../assets/fonts/ItauText_A_Lt.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauText-Regular';
  src: local('ItauText-Regular'), url(../assets/fonts/ItauText_A_Rg.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauText-Bold';
  src: local('ItauText-Bold'), url(../assets/fonts/ItauText_A_Bd.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauText-XBold';
  src: local('ItauText-XBold'), url(../assets/fonts/ItauText_A_XBd.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauDisplay-Light';
  src: local('ItauDisplay-Light'), url(../assets/fonts/itauDisplay/ItauDisplay_Lt.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauDisplay-Light';
  src: local('ItauDisplay-Light'),
    url(../assets/fonts/itauDisplay/ItauDisplay_Lt.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauDisplay-Regular';
  src: local('ItauDisplay-Regular'), url(../assets/fonts/itauDisplay/ItauDisplay_Rg.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauDisplay-Bold';
  src: local('ItauDisplay-Bold'), url(../assets/fonts/itauDisplay/ItauDisplay_Bd.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'ItauDisplayPro-Bold';
  src: local('ItauDisplayPro-Bold'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_Bd.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'ItauDisplayPro-Black';
  src: local('ItauDisplayPro-Black'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_Blk.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'ItauDisplayPro-Heavy';
  src: local('ItauDisplayPro-Heavy'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_He.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'ItauDisplayPro-Light';
  src: local('ItauDisplayPro-Light'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_Lt.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'ItauDisplayPro-Regular';
  src: local('ItauDisplayPro-Regular'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_Rg.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'ItauDisplayPro-XBold';
  src: local('ItauDisplayPro-XBold'), url(../assets/fonts/itauDisplayPro/ItauDisplayPro_W_XBd.woff) format('woff');
  font-display: swap;
}

