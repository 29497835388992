@import '../../styles/_mixins.scss';
.Snackbar {
    max-width: 100%;

    @include mq('desktop') {
        max-width: 650px;
    }

    .success {
        background-color: #007A47;
    }

    .warning {
        background-color: yellow;
    }

    .info {
        background-color: #007AB7;
    }

    .error {
        background-color: #531E6D;
    }

    .default {
        background-color: black;
    }

    .validation {
        background-color: #f1ae2f;
        color:black;

        span {
        color:black;
        }
    }

    .SnackbarContent {
        font-family: 'ItauText-Regular';
        flex-wrap: nowrap;
        max-width: 100vw;
        min-width: 36vw;

        b, .message-title {
            font-family: 'ItauText-Bold';
        }

        a {
            color: inherit;
        }
    }

    .IconButton{
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        i {
            font-size: 23px;
            color: #fff;
        }
    }

}