@import '../styles/_mixins.scss';
.App {
  display: flex;
  height: -webkit-fill-available;
  width: 100vw;
  min-height: 90vh;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;

  @include mq('tablet') {
    height: 100vh;
  }

  .content {
    width: 100%;
    position: relative;
  }
}

::-webkit-scrollbar {
  width: 7px;
  @media only screen and (max-width: 899px) {
    width: 0px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(71, 71, 71, 0.911);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(71, 71, 71, 0.911);
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar-track {
    box-shadow: none;
  }

  ::-webkit-scrollbar-thumb {
    background: none;
  }
}
