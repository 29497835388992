@import '../../styles/mixins';
@import '../../styles/_itaucolors.scss';
@import '../../styles/_variables.scss';

.button-group {
  display: flex;

  &--center {
    justify-content: center;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  $size: 8;
  @for $i from 1 to 10 {
    &--mt-#{$i * $size} {
      margin: 0;
      margin-top: $i * $size + px;
    }

    &--mr-#{$i * $size} {
      margin: 0;
      margin-right: $i * $size + px;
    }

    &--mb-#{$i * $size} {
      margin: 0;
      margin-bottom: $i * $size + px;
    }

    &--mh-#{$i * $size} {
      margin: 0;
      margin-top: $i * $size + px;
      margin-bottom: $i * $size + px;
    }
  }

  .button {
    font-family: $button-font-family;
    text-transform: lowercase;
    border-radius: $button-border-radius;
    font-weight: bold;
    box-shadow: none;
    padding: 0;
    text-align: center;
    font-size: 1.375rem;
    min-height: 3rem;
    height:100%;

    &--fz-16 {
      font-size: 1rem;
    }

    &--fz-19 {
      font-size: 1.1875rem;
    }

    &--height-24 {
      height: 1.5rem;
    }

    &--orange {
      color: $white;

      &.button--outlined {
        background-color: $white;
        border: 1px solid $orange;
        color: $orange;
      }
    }

    &--blue {
      color: #106EB0;

      &.button--outlined {
        background-color: $white;
        border: 1px solid #106EB0;
      }
    }

    &--brown {
      color: #605751;

      &:hover {
        background-color: rgba(96, 87, 81, 0.2);
      }
    }

    &--expand {
      width: 100%;
    }

    &--small {
      padding: 0 12px;

      @include mq('phone', 'max') {
        width: 100%;
      }

      @include mq('tablet') {
        padding: 0 24px;
      }

      @include mq('tablet-wide') {
        padding: 0 32px;
      }
    }

    &--medium {
      padding: 0 24px;

      @include mq('tablet') {
        padding: 0 32px;
      }

      @include mq('tablet-wide') {
        padding: 0 40px;
      }
    }

    &--large {
      padding: 0 32px;

      @include mq('tablet') {
        padding: 0 40px;
      }

      @include mq('tablet-wide') {
        padding: 0 48px;
      }
    }

    &[disabled] {
      background-color: $button-bg-disabled;
      color: $button-color-disabled;
      cursor: default;
      pointer-events: none;
    }

    &[role='link'] {
      text-decoration: underline;
      font-size: 16px;
      color: $linkColor;
      font-family: ItauText-Bold;
      background-color: transparent;
      text-align: left;
      min-height: 0;
      @include mq('phone-wide', 'max') {
        font-size: 15px;
      }
    }

  }
}

.button-selected {
  color: $white !important;
  background-color: $orange !important;
}