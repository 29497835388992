@font-face {
  font-family: 'Itau_Icons';
  src: url('../assets/fonts/Itau_Icons.ttf?hpnmfc') format('truetype'),
    url('../assets/fonts/Itau_Icons.svg?hpnmfc#Itau_Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Itau_Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-itaufonts_acessibilidade_full:before {
  content: '\e9df';
}
.icon-itaufonts_acessibilidade:before {
  content: '\eaad';
}
.icon-itaufonts_openbanking:before {
  content: '\eadc';
}
.icon-itaufonts_openbanking_full:before {
  content: '\eadd';
}
.icon-itaufonts_pix_outline:before {
  content: '\ead6';
}
.icon-itaufonts_pix_full:before {
  content: '\ead7';
}
.icon-itaufonts_presente_outline:before {
  content: '\eac1';
}
.icon-itaufonts_presente_negativo:before {
  content: '\eac0';
}
.icon-itaufonts_flash_desabilitado:before {
  content: '\eabd';
}
.icon-itaufonts_flash_desabilitado_full:before {
  content: '\eabf';
}
.icon-itaufonts_flash_habilitado:before {
  content: '\eabe';
}
.icon-itaufonts_presente:before {
  content: '\eabc';
}
.icon-itaufonts_desafios_full:before {
  content: '\eab5';
}
.icon-itaufonts_desafios:before {
  content: '\eab6';
}
.icon-itaufonts_universitarios:before {
  content: '\e9bf';
}
.icon-itaufonts_full_universitarios:before {
  content: '\ea68';
}
.icon-itaufonts_loja:before {
  content: '\eabb';
}
.icon-itaufonts_loja_full:before {
  content: '\eaba';
}
.icon-itaufonts_residencia:before {
  content: '\e995';
}
.icon-itaufonts_full_residencia:before {
  content: '\ea45';
}
.icon-itaufonts_para_empresa:before {
  content: '\e980';
}
.icon-itaufonts_full_para_empresa:before {
  content: '\ea32';
}
.icon-itaufonts_outline_selecao_terceiros:before {
  content: '\eaae';
}
.icon-itaufonts_full_selecao_terceiros:before {
  content: '\eaaf';
}
.icon-itaufonts_agencia:before {
  content: '\e906';
}
.icon-itaufonts_full_agencia:before {
  content: '\e9d3';
}
.icon-itaufonts_agencia_personalite:before {
  content: '\e905';
}
.icon-itaufonts_full_agencia_personalite:before {
  content: '\e9d2';
}
.icon-itaufonts_outline_copiar:before {
  content: '\eaa1';
}
.icon-itaufonts_full_copiar:before {
  content: '\eaa4';
}
.icon-itaufonts_restaurar:before {
  content: '\e997';
}
.icon-itaufonts_full_restaurar:before {
  content: '\ea47';
}
.icon-itaufonts_outline_enviar:before {
  content: '\ea9a';
}
.icon-itaufonts_full_enviar:before {
  content: '\ea9c';
}
.icon-itaufonts_coleta:before {
  content: '\e92a';
}
.icon-itaufonts_full_coleta:before {
  content: '\e9ed';
}
.icon-itaufonts_outline_responder:before {
  content: '\eaa2';
}
.icon-itaufonts_full_responder:before {
  content: '\eaa5';
}
.icon-itaufonts_outline_beleza:before {
  content: '\ea9f';
}
.icon-itaufonts_full_beleza:before {
  content: '\ea9e';
}
.icon-itaufonts_compras_vendas:before {
  content: '\e92b';
}
.icon-itaufonts_full_compras_vendas:before {
  content: '\e9ee';
}
.icon-itaufonts_xicara:before {
  content: '\e9c7';
}
.icon-itaufonts_full_xicara:before {
  content: '\ea70';
}
.icon-itaufonts_outline_suporte:before {
  content: '\ea96';
}
.icon-itaufonts_full_suporte:before {
  content: '\ea97';
}
.icon-itaufonts_outline_compras:before {
  content: '\ea95';
}
.icon-itaufonts_full_compras:before {
  content: '\ea94';
}
.icon-itaufonts_outline_pet:before {
  content: '\ea91';
}
.icon-itaufonts_full_pet:before {
  content: '\ea90';
}
.icon-itaufonts_outline_olho_inativo:before {
  content: '\ea8c';
}
.icon-itaufonts_full_olho_inativo:before {
  content: '\ea8a';
}
.icon-itaufonts_outline_olho_ativo:before {
  content: '\ea8d';
}
.icon-itaufonts_full_olho_ativo:before {
  content: '\ea8b';
}
.icon-itaufonts_vestuario:before {
  content: '\ea88';
}
.icon-itaufonts_full_vestuario:before {
  content: '\ea89';
}
.icon-itaufonts_relogio:before {
  content: '\e993';
}
.icon-itaufonts_full_relogio:before {
  content: '\ea44';
}
.icon-itaufonts_chat_erro:before {
  content: '\ea82';
}
.icon-itaufonts_full_chat_erro:before {
  content: '\ea81';
}
.icon-itaufonts_chat_aguardando:before {
  content: '\ea84';
}
.icon-itaufonts_full_chat_aguardando:before {
  content: '\ea83';
}
.icon-itaufonts_microfone:before {
  content: '\ea86';
}
.icon-itaufonts_full_microfone:before {
  content: '\ea85';
}
.icon-itaufonts_atendimento_2:before {
  content: '\ea7c';
}
.icon-itaufonts_full_atendimento:before {
  content: '\ea7e';
}
.icon-itaufonts_sms_mensagem_chat:before {
  content: '\e9b3';
}
.icon-itaufonts_full_sms_mensagem_chat:before {
  content: '\ea5e';
}
.icon-itaufonts_feedback:before {
  content: '\e9c9';
}
.icon-itaufonts_full_feedback:before {
  content: '\ea72';
}
.icon-itaufonts_exportar:before {
  content: '\e952';
}
.icon-itaufonts_full_exportar:before {
  content: '\ea10';
}
.icon-itaufonts_download:before {
  content: '\e947';
}
.icon-itaufonts_full_download:before {
  content: '\ea06';
}
.icon-itaufonts_venda:before {
  content: '\ea7a';
}
.icon-itaufonts_full_venda:before {
  content: '\ea78';
}
.icon-itaufonts_compra:before {
  content: '\ea7b';
}
.icon-itaufonts_full_compra:before {
  content: '\ea79';
}
.icon-itaufonts_video:before {
  content: '\e9c5';
}
.icon-itaufonts_full_video:before {
  content: '\ea6e';
}
.icon-pause:before {
  content: '\eae2';
}
.icon-pause_full:before {
  content: '\eae3';
}
.icon-itaufonts_50_cinema:before {
  content: '\e901';
}
.icon-itaufonts_full_50_cinema:before {
  content: '\e9cf';
}
.icon-itaufonts_50_futebol:before {
  content: '\e902';
}
.icon-itaufonts_full_50_futebol:before {
  content: '\e9d0';
}
.icon-itaufonts_50_teatro:before {
  content: '\e903';
}
.icon-itaufonts_full_50_teatro:before {
  content: '\e9d1';
}
.icon-itaufonts_full_agil_descomplic:before {
  content: '\e9d4';
}
.icon-itaufonts_agil_descomplic:before {
  content: '\e907';
}
.icon-itaufonts_alimentacao:before {
  content: '\e909';
}
.icon-itaufonts_full_alimentacao:before {
  content: '\e9d5';
}
.icon-itaufonts_alvo:before {
  content: '\e90a';
}
.icon-itaufonts_full_alvo:before {
  content: '\e9d6';
}
.icon-itaufonts_salarios:before {
  content: '\e999';
}
.icon-itaufonts_full_salarios:before {
  content: '\ea48';
}
.icon-itaufonts_lavagem_de_dinheiro:before {
  content: '\e96e';
}
.icon-itaufonts_full_lavagem_de_dinheiro:before {
  content: '\ea24';
}
.icon-itaufonts_antecip_de_recebiveis:before {
  content: '\e90b';
}
.icon-itaufonts_full_antecip_de_recebiveis:before {
  content: '\e9d7';
}
.icon-itaufonts_aplicativos:before {
  content: '\e90d';
}
.icon-itaufonts_full_aplicativos:before {
  content: '\e9d8';
}
.icon-itaufonts_aporte_de_capital:before {
  content: '\e90e';
}
.icon-itaufonts_full_aporte_de_capital:before {
  content: '\e9d9';
}
.icon-itaufonts_ar_condicionado:before {
  content: '\e90f';
}
.icon-itaufonts_full_ar_condicionado:before {
  content: '\e9da';
}
.icon-itaufonts_armario:before {
  content: '\e910';
}
.icon-itaufonts_full_armario:before {
  content: '\e9db';
}
.icon-itaufonts_atualizando:before {
  content: '\e914';
}
.icon-itaufonts_full_atualizando:before {
  content: '\e9dc';
}
.icon-itaufonts_balanco_de_caixa:before {
  content: '\e916';
}
.icon-itaufonts_full_balanco_de_caixa:before {
  content: '\e9de';
}
.icon-itaufonts_biometria:before {
  content: '\e918';
}
.icon-itaufonts_full_biometria:before {
  content: '\e9e0';
}
.icon-itaufonts_brilhos_nos_olhos:before {
  content: '\e919';
}
.icon-itaufonts_full_brilhos_nos_olhos:before {
  content: '\e9e1';
}
.icon-itaufonts_busca_consulta:before {
  content: '\e91a';
}
.icon-itaufonts_full_busca_consulta:before {
  content: '\e9e2';
}
.icon-itaufonts_caixa_eletronico:before {
  content: '\e91b';
}
.icon-itaufonts_full_caixa_eletronico:before {
  content: '\e9e3';
}
.icon-itaufonts_cartao_de_seguranca:before {
  content: '\e920';
}
.icon-itaufonts_full_cartao_de_seguranca:before {
  content: '\e9e6';
}
.icon-itaufonts_chip_outline:before {
  content: '\eaa9';
}
.icon-itaufonts_chip:before {
  content: '\eaa8';
}
.icon-itaufonts_cartao:before {
  content: '\e921';
}
.icon-itaufonts_full_cartao:before {
  content: '\e9e7';
}
.icon-itaufonts_cartoes:before {
  content: '\e923';
  color: #211915;
}
.icon-itaufonts_full_cartoes:before {
  content: '\e9e8';
  color: #211915;
}
.icon-itaufonts_estorno:before {
  content: '\eade';
}
.icon-itaufonts_estorno_full:before {
  content: '\eadf';
}
.icon-icone_cartao_virtual:before {
  content: '\eac2';
}
.icon-itaufonts_outline_carteira_digital:before {
  content: '\ea93';
}
.icon-itaufonts_full_carteira_digital:before {
  content: '\ea92';
}
.icon-itaufonts_celular_pre_pago:before {
  content: '\e924';
}
.icon-itaufonts_full_celular_pre_pago:before {
  content: '\e9e9';
}
.icon-itaufonts_celular:before {
  content: '\e925';
}
.icon-itaufonts_full_celular:before {
  content: '\e9ea';
}
.icon-itaufonts_autoriz_pelo_celular:before {
  content: '\e915';
}
.icon-itaufonts_full_autoriz_pelo_celular:before {
  content: '\e9dd';
}
.icon-itaufonts_computador_internet:before {
  content: '\e92d';
}
.icon-itaufonts_full_computador_internet:before {
  content: '\e9f0';
}
.icon-itaufonts_tv:before {
  content: '\e9bd';
}
.icon-itaufonts_tablet:before {
  content: '\e9b8';
}
.icon-itaufonts_full_tablet:before {
  content: '\ea63';
}
.icon-itaufonts_telefone:before {
  content: '\e9b9';
}
.icon-itaufonts_full_telefone:before {
  content: '\ea64';
}
.icon-itaufonts_pos_maq_adquirente:before {
  content: '\e989';
}
.icon-itaufonts_full_pos_maq_adquirente:before {
  content: '\ea3b';
}
.icon-itaufonts_consorcio_auto:before {
  content: '\e931';
}
.icon-itaufonts_full_consorcio_auto:before {
  content: '\e9f2';
}
.icon-itaufonts_consorcio_imobiliario:before {
  content: '\e932';
}
.icon-itaufonts_full_consorcio_imobiliario:before {
  content: '\e9f3';
}
.icon-itaufonts_consorcio:before {
  content: '\e933';
}
.icon-itaufonts_full_consorcio:before {
  content: '\e9f4';
}
.icon-itaufonts_saldo:before {
  content: '\e99a';
}
.icon-itaufonts_full_saldo:before {
  content: '\ea49';
}
.icon-itaufonts_conta_corrente:before {
  content: '\e934';
}
.icon-itaufonts_full_conta_corrente:before {
  content: '\e9f5';
}
.icon-itaufonts_outline_carteiras_digitais:before {
  content: '\eab1';
}
.icon-itaufonts_full_carteiras_digitais:before {
  content: '\eab2';
}
.icon-itaufonts_contin_negocios:before {
  content: '\e936';
}
.icon-itaufonts_diario_outline:before {
  content: '\eac3';
}
.icon-itaufonts_diario_full:before {
  content: '\eac4';
}
.icon-itaufonts_calendario:before {
  content: '\e91e';
}
.icon-itaufonts_full_calendario:before {
  content: '\e9e4';
}
.icon-itaufonts_hist_de_rentabilidade:before {
  content: '\e95e';
}
.icon-itaufonts_full_hist_de_rentabilidade:before {
  content: '\ea19';
}
.icon-itaufonts_credito_automatico:before {
  content: '\e939';
}
.icon-itaufonts_full_credito_automatico:before {
  content: '\e9f9';
}
.icon-itaufonts_credito_auto:before {
  content: '\e938';
}
.icon-itaufonts_full_credito_auto:before {
  content: '\e9f8';
}
.icon-itaufonts_credito_consignado:before {
  content: '\e93a';
}
.icon-itaufonts_full_credito_consignado:before {
  content: '\e9fa';
}
.icon-itaufonts_credito_imobiliario:before {
  content: '\e93b';
}
.icon-itaufonts_full_credito_imobiliario:before {
  content: '\e9fb';
}
.icon-itaufonts_credito:before {
  content: '\e93d';
}
.icon-itaufonts_full_credito:before {
  content: '\e9fd';
}
.icon-itaufonts_maiores_limites2:before {
  content: '\e972';
}
.icon-itaufonts_full_maiores_limites:before {
  content: '\ea27';
}
.icon-itaufonts_credito_sob_medida:before {
  content: '\e93c';
}
.icon-itaufonts_full_credito_sob_medida:before {
  content: '\e9fc';
}
.icon-itaufonts_orientador_de_credito:before {
  content: '\e97c';
}
.icon-itaufonts_full_orientador_de_credito:before {
  content: '\ea30';
}
.icon-itaufonts_cultura:before {
  content: '\e93e';
}
.icon-itaufonts_full_cultura:before {
  content: '\e9fe';
}
.icon-itaufonts_curtir:before {
  content: '\e93f';
}
.icon-itaufonts_full_curtir:before {
  content: '\e9ff';
}
.icon-itaufonts_descurtir:before {
  content: '\e943';
}
.icon-itaufonts_full_descurtir:before {
  content: '\ea02';
}
.icon-itaufonts_debito:before {
  content: '\e940';
}
.icon-itaufonts_full_debito:before {
  content: '\ea00';
}
.icon-itaufonts_despesas_com_saude:before {
  content: '\e944';
}
.icon-itaufonts_full_despesas_com_saude:before {
  content: '\ea03';
}
.icon-itaufonts_educacao_uso_consc:before {
  content: '\e949';
}
.icon-itaufonts_full_educacao_uso_consc:before {
  content: '\ea07';
}
.icon-itaufonts_email_resp_rapida:before {
  content: '\e94a';
}
.icon-itaufonts_full_email_resp_rapida:before {
  content: '\ea08';
}
.icon-itaufonts_emprestimos:before {
  content: '\e94b';
}
.icon-itaufonts_full_emprestimos:before {
  content: '\ea09';
}
.icon-itaufonts_esportes:before {
  content: '\e94c';
}
.icon-itaufonts_full_esportes:before {
  content: '\ea0a';
}
.icon-itaufonts_estrela:before {
  content: '\e94d';
}
.icon-itaufonts_full_estrela:before {
  content: '\ea0b';
}
.icon-itaufonts_exclamacao:before {
  content: '\e94f';
}
.icon-itaufonts_full_exclamacao:before {
  content: '\ea0d';
}
.icon-itaufonts_excluir:before {
  content: '\e950';
}
.icon-itaufonts_full_excluir:before {
  content: '\ea0e';
}
.icon-itaufonts_exclusivo_beneficios:before {
  content: '\e951';
}
.icon-itaufonts_full_exclusivo_beneficios:before {
  content: '\ea0f';
}
.icon-itaufonts_comprovante:before {
  content: '\e92c';
}
.icon-itaufonts_full_comprovante:before {
  content: '\e9ef';
}
.icon-itaufonts_cheque:before {
  content: '\e928';
}
.icon-itaufonts_full_cheque:before {
  content: '\e9ec';
}
.icon-itaufonts_impressora:before {
  content: '\e95f';
}
.icon-itaufonts_full_impressora:before {
  content: '\ea1a';
}
.icon-itaufonts_docs:before {
  content: '\e945';
}
.icon-itaufonts_full_docs:before {
  content: '\ea04';
}
.icon-itaufonts_documento:before {
  content: '\e946';
}
.icon-itaufonts_full_documento:before {
  content: '\ea05';
}
.icon-itaufonts_fatura:before {
  content: '\e955';
}
.icon-itaufonts_full_fatura:before {
  content: '\ea12';
}
.icon-itaufonts_garantia:before {
  content: '\e959';
}
.icon-itaufonts_full_garantia:before {
  content: '\ea15';
}
.icon-itaufonts_jornal:before {
  content: '\e96c';
}
.icon-itaufonts_full_jornal:before {
  content: '\ea23';
}
.icon-itaufonts_filtro:before {
  content: '\e957';
}
.icon-itaufonts_full_filtro:before {
  content: '\ea13';
}
.icon-itaufonts_foco_inovacao:before {
  content: '\e958';
}
.icon-itaufonts_full_foco_inovacao:before {
  content: '\ea14';
}
.icon-itaufonts_gerenciador_de_vendas:before {
  content: '\e95b';
}
.icon-itaufonts_full_gerenciador_de_vendas:before {
  content: '\ea16';
}
.icon-itaufonts_gestao_de_crises:before {
  content: '\e95d';
}
.icon-itaufonts_full_gestao_de_crises:before {
  content: '\ea18';
}
.icon-itaufonts_carteirada:before {
  content: '\e922';
}
.icon-itaufonts_processos:before {
  content: '\e98e';
}
.icon-itaufonts_full_processos:before {
  content: '\ea40';
}
.icon-itaufonts_portabilidade:before {
  content: '\e988';
}
.icon-itaufonts_full_portabilidade:before {
  content: '\ea3a';
}
.icon-itaufonts_pjto_sucessorio:before {
  content: '\e987';
}
.icon-itaufonts_full_pjto_sucessorio:before {
  content: '\ea39';
}
.icon-itaufonts_craques:before {
  content: '\e937';
}
.icon-itaufonts_full_craques:before {
  content: '\e9f7';
}
.icon-itaufonts_contatos:before {
  content: '\e935';
}
.icon-itaufonts_full_contatos:before {
  content: '\e9f6';
}
.icon-itaufonts_documento_outline:before {
  content: '\eada';
}
.icon-itaufonts_documento_full:before {
  content: '\ead9';
}
.icon-itaufonts_lideranca:before {
  content: '\e970';
}
.icon-itaufonts_full_lideranca:before {
  content: '\ea25';
}
.icon-itaufonts_usuario_perfil:before {
  content: '\e9c0';
}
.icon-itaufonts_full_usuario_perfil:before {
  content: '\ea69';
}
.icon-itaufonts_gerente:before {
  content: '\e95c';
}
.icon-itaufonts_full_gerente:before {
  content: '\ea17';
}
.icon-itaufonts_ger_dedicados:before {
  content: '\e95a';
}
.icon-itaufonts_cons_investimentos:before {
  content: '\e930';
}
.icon-itaufonts_maiores_limites:before {
  content: '\e963';
}
.icon-itaufonts_atend_telefonico:before {
  content: '\e913';
}
.icon-itaufonts_caixas_exclusivos:before {
  content: '\e91c';
}
.icon-itaufonts_ajuda:before {
  content: '\e908';
}
.icon-itaufonts_full_ajuda:before {
  content: '\ea9d';
}
.icon-itaufonts_informacao:before {
  content: '\e960';
}
.icon-itaufonts_full_informacao:before {
  content: '\ea1b';
}
.icon-itaufonts_investimento:before {
  content: '\e965';
}
.icon-itaufonts_full_investimento:before {
  content: '\ea1e';
}
.icon-itaufonts_mercado_noticias:before {
  content: '\e978';
}
.icon-itaufonts_full_mercado_noticias:before {
  content: '\ea2c';
}
.icon-itaufonts_acoes:before {
  content: '\e904';
}
.icon-itaufonts_itoken_aplicativo:before {
  content: '\e969';
}
.icon-itaufonts_full_itoken_aplicativo:before {
  content: '\ea20';
}
.icon-itaufonts_itoken_chaveiro:before {
  content: '\e96a';
}
.icon-itaufonts_full_itoken_chaveiro:before {
  content: '\ea21';
}
.icon-itaufonts_itoken_sms:before {
  content: '\e96b';
}
.icon-itaufonts_full_itoken_sms:before {
  content: '\ea22';
}
.icon-itaufonts_limites:before {
  content: '\e971';
}
.icon-itaufonts_full_limites:before {
  content: '\ea26';
}
.icon-itaufonts_mala:before {
  content: '\e974';
}
.icon-itaufonts_full_mala:before {
  content: '\ea28';
}
.icon-itaufonts_maq_rede_cartao:before {
  content: '\e975';
  color: #211915;
}
.icon-itaufonts_full_maq_rede_cartao:before {
  content: '\ea29';
  color: #211915;
}
.icon-itaufonts_maquina_fotografica:before {
  content: '\e976';
}
.icon-itaufonts_full_maquina_fotografica:before {
  content: '\ea2a';
}
.icon-itaufonts_maximizar:before {
  content: '\e977';
}
.icon-itaufonts_full_maximizar:before {
  content: '\ea2b';
}
.icon-itaufonts_institucional:before {
  content: '\e964';
}
.icon-itaufonts_full_institucional:before {
  content: '\ea1d';
}
.icon-itaufonts_outline_checkbox_indeterminado:before {
  content: '\e832';
}
.icon-itaufonts_checkbox_indeterminado:before {
  content: '\e831';
}
.icon-itaufonts_outline_checkbox:before {
  content: '\ea98';
}
.icon-itaufonts_full_checkbox:before {
  content: '\ea9b';
}
.icon-itaufonts_minimizar:before {
  content: '\e979';
}
.icon-itaufonts_full_minimizar:before {
  content: '\ea2d';
}
.icon-itaufonts_senha_stroke_slim:before {
  content: '\ea7d';
}
.icon-itaufonts_full_senha_fill:before {
  content: '\ea74';
}
.icon-itaufonts_switch:before {
  content: '\e9b7';
}
.icon-itaufonts_full_switch:before {
  content: '\ea62';
}
.icon-itaufonts_mundo_mapa:before {
  content: '\e97a';
}
.icon-itaufonts_full_mundo_mapa:before {
  content: '\ea2e';
}
.icon-itaufonts_notificacao:before {
  content: '\e97b';
}
.icon-itaufonts_full_notificacao:before {
  content: '\ea2f';
}
.icon-itaufonts_paixao:before {
  content: '\e97f';
}
.icon-itaufonts_full_paixao:before {
  content: '\ea31';
}
.icon-itaufonts_parcelamento:before {
  content: '\e981';
}
.icon-itaufonts_full_parcelamento:before {
  content: '\ea33';
}
.icon-itaufonts_pare_de_fumar:before {
  content: '\e982';
}
.icon-itaufonts_full_pare_de_fumar:before {
  content: '\ea34';
}
.icon-itaufonts_perfil_do_investidor:before {
  content: '\e984';
}
.icon-itaufonts_full_perfil_do_investidor:before {
  content: '\ea36';
}
.icon-itaufonts_pers_conta:before {
  content: '\e985';
}
.icon-itaufonts_full_pers_conta:before {
  content: '\ea37';
}
.icon-itaufonts_pgto_ao_fornecedor:before {
  content: '\e986';
}
.icon-itaufonts_full_pgto_ao_fornecedor:before {
  content: '\ea38';
}
.icon-itaufonts_poupanca:before {
  content: '\e98a';
}
.icon-itaufonts_full_poupanca:before {
  content: '\ea3c';
}
.icon-itaufonts_premio:before {
  content: '\e98b';
}
.icon-itaufonts_full_premio:before {
  content: '\ea3d';
}
.icon-itaufonts_prevencao_de_fraude:before {
  content: '\e98c';
}
.icon-itaufonts_full_prevencao_de_fraude:before {
  content: '\ea3e';
}
.icon-itaufonts_previdencia:before {
  content: '\e98d';
}
.icon-itaufonts_full_previdencia:before {
  content: '\ea3f';
}
.icon-itaufonts_configuracoes:before {
  content: '\e92f';
}
.icon-itaufonts_full_configuracoes:before {
  content: '\e9f1';
}
.icon-itaufonts_extrato_lancamentos:before {
  content: '\e953';
}
.icon-itaufonts_produtos_servicos:before {
  content: '\e98f';
}
.icon-itaufonts_full_produtos_servicos:before {
  content: '\ea41';
}
.icon-itaufonts_programa_de_pontos:before {
  content: '\e990';
}
.icon-itaufonts_full_programa_de_pontos:before {
  content: '\ea42';
}
.icon-itaufonts_outline_timeline:before {
  content: '\eaa6';
}
.icon-itaufonts_full_timeline:before {
  content: '\eaa7';
}
.icon-itaufonts_radio:before {
  content: '\e991';
}
.icon-itaufonts_full_radio:before {
  content: '\ea43';
}
.icon-itaufonts_respons_social:before {
  content: '\e996';
}
.icon-itaufonts_full_respons_social:before {
  content: '\ea46';
}
.icon-itaufonts_salvar:before {
  content: '\e99b';
}
.icon-itaufonts_full_salvar:before {
  content: '\ea4a';
}
.icon-itaufonts_chave_patrimonio:before {
  content: '\e926';
}
.icon-itaufonts_full_chave_patrimonio:before {
  content: '\e9eb';
}
.icon-itaufonts_seguranca:before {
  content: '\e9a0';
}
.icon-itaufonts_full_seguranca:before {
  content: '\ea4e';
}
.icon-itaufonts_desbloqueio:before {
  content: '\e942';
}
.icon-itaufonts_full_desbloqueio:before {
  content: '\ea01';
}
.icon-itaufonts_seg_informacao:before {
  content: '\e99d';
}
.icon-itaufonts_full_seg_informacao:before {
  content: '\ea4b';
}
.icon-itaufonts_seg_patrimonial:before {
  content: '\e99e';
}
.icon-itaufonts_full_seg_patrimonial:before {
  content: '\ea4c';
}
.icon-itaufonts_seg_redes_sociais:before {
  content: '\e99f';
}
.icon-itaufonts_full_seg_redes_sociais:before {
  content: '\ea4d';
}
.icon-itaufonts_senha:before {
  content: '\e9b0';
}
.icon-itaufonts_full_senha:before {
  content: '\ea5d';
}
.icon-itaufonts_itaupass_outline:before {
  content: '\eace';
}
.icon-itaufonts_itaupass_full:before {
  content: '\eacd';
}
.icon-itaufonts_seguros:before {
  content: '\e9af';
}
.icon-itaufonts_seguro_celular:before {
  content: '\eae0';
}
.icon-itaufonts_seguro_celular_full:before {
  content: '\eae1';
}
.icon-itaufonts_outline_seguro_fraude:before {
  content: '\eaa0';
}
.icon-itaufonts_full_seguro_fraude:before {
  content: '\eaa3';
}
.icon-itaufonts_outline_seguro_pet:before {
  content: '\ea8f';
}
.icon-itaufonts_full_seguro_pet:before {
  content: '\ea8e';
}
.icon-itaufonts_seguro_auto:before {
  content: '\e9a1';
}
.icon-itaufonts_full_seguro_auto:before {
  content: '\ea4f';
}
.icon-itaufonts_seguro_cartao:before {
  content: '\e9a2';
}
.icon-itaufonts_full_seguro_cartao:before {
  content: '\ea50';
}
.icon-itaufonts_seguro_crianca:before {
  content: '\e9a3';
}
.icon-itaufonts_full_seguro_crianca:before {
  content: '\ea51';
}
.icon-itaufonts_seguro_de_credito:before {
  content: '\e9a4';
}
.icon-itaufonts_full_seguro_de_credito:before {
  content: '\ea52';
}
.icon-itaufonts_seguro_empresas:before {
  content: '\e9a5';
}
.icon-itaufonts_full_seguro_empresas:before {
  content: '\ea53';
}
.icon-itaufonts_seguro_mulher:before {
  content: '\e9a6';
}
.icon-itaufonts_full_seguro_mulher:before {
  content: '\ea54';
}
.icon-itaufonts_seguro_patrimonial:before {
  content: '\e9a7';
}
.icon-itaufonts_full_seguro_patrimonial:before {
  content: '\ea55';
}
.icon-itaufonts_seguro_prestamista:before {
  content: '\e9a8';
}
.icon-itaufonts_full_seguro_prestamista:before {
  content: '\ea56';
}
.icon-itaufonts_seguro_renda:before {
  content: '\e9a9';
}
.icon-itaufonts_full_seguro_renda:before {
  content: '\ea57';
}
.icon-itaufonts_seguro_residencia:before {
  content: '\e9aa';
}
.icon-itaufonts_full_seguro_residencia:before {
  content: '\ea58';
}
.icon-itaufonts_seguro_resp_social:before {
  content: '\e9ab';
}
.icon-itaufonts_full_seguro_resp_social:before {
  content: '\ea59';
}
.icon-itaufonts_seguro_transporte:before {
  content: '\e9ac';
}
.icon-itaufonts_full_seguro_transporte:before {
  content: '\ea5a';
}
.icon-itaufonts_seguro_viagens:before {
  content: '\e9ad';
}
.icon-itaufonts_full_seguro_viagens:before {
  content: '\ea5b';
}
.icon-itaufonts_seguro_vida:before {
  content: '\e9ae';
}
.icon-itaufonts_full_seguro_vida:before {
  content: '\ea5c';
}
.icon-itaufonts_sonho_grande:before {
  content: '\e9b4';
}
.icon-itaufonts_full_sonho_grande:before {
  content: '\ea5f';
}
.icon-itaufonts_sorriso:before {
  content: '\e9b5';
}
.icon-itaufonts_full_sorriso:before {
  content: '\ea60';
}
.icon-itaufonts_sustentabilidade:before {
  content: '\e9b6';
}
.icon-itaufonts_full_sustentabilidade:before {
  content: '\ea61';
}
.icon-itaufonts_transporte:before {
  content: '\e9bc';
}
.icon-itaufonts_full_transporte:before {
  content: '\ea66';
}
.icon-itaufonts_metro_outline:before {
  content: '\eac5';
}
.icon-itaufonts_metro_full:before {
  content: '\eac6';
}
.icon-itaufonts_onibus_outline:before {
  content: '\eac7';
}
.icon-itaufonts_onibus_full:before {
  content: '\eac8';
}
.icon-itaufonts_veiculo:before {
  content: '\e9c1';
}
.icon-itaufonts_full_veiculo:before {
  content: '\ea6a';
}
.icon-itaufonts_viagem:before {
  content: '\e9c2';
}
.icon-itaufonts_full_viagem:before {
  content: '\ea6b';
}
.icon-itaufonts_outline_moto:before {
  content: '\e917';
}
.icon-itaufonts_bike_outline:before {
  content: '\eadb';
}
.icon-itaufonts_outline_caminhao:before {
  content: '\eaaa';
}
.icon-itaufonts_outline_caminhonete:before {
  content: '\eaac';
}
.icon-itaufonts_vida:before {
  content: '\e9c4';
}
.icon-itaufonts_full_vida:before {
  content: '\ea6d';
}
.icon-itaufonts_vida_sustentavel:before {
  content: '\e9c3';
}
.icon-itaufonts_full_vida_sustentavel:before {
  content: '\ea6c';
}
.icon-itaufonts_excel:before {
  content: '\e94e';
}
.icon-itaufonts_full_excel:before {
  content: '\ea0c';
}
.icon-itaufonts_relatorios_financeiros:before {
  content: '\e9ca';
}
.icon-itaufonts_full_relatorios_financeiros:before {
  content: '\ea73';
}
.icon-itaufonts_pdf:before {
  content: '\e983';
}
.icon-itaufonts_full_pdf:before {
  content: '\ea35';
}
.icon-itaufonts_word:before {
  content: '\e9c6';
}
.icon-itaufonts_full_word:before {
  content: '\ea6f';
}
.icon-itaufonts_txt_outline:before {
  content: '\ead0';
}
.icon-itaufonts_txt_full:before {
  content: '\eacf';
}
.icon-itaufonts_csv_outline:before {
  content: '\ead2';
}
.icon-itaufonts_csv_full:before {
  content: '\eacc';
}
.icon-itaufonts_cambio:before {
  content: '\e91f';
}
.icon-itaufonts_full_cambio:before {
  content: '\e9e5';
}
.icon-itaufonts_compartilhar:before {
  content: '\e9c8';
}
.icon-itaufonts_todos_pelo_cliente:before {
  content: '\e9ba';
}
.icon-itaufonts_full_todos_pelo_cliente:before {
  content: '\ea65';
}
.icon-itaufonts_full_compartilhar:before {
  content: '\ea71';
}
.icon-itaufonts_link_externo_outline:before {
  content: '\eaca';
}
.icon-itaufonts_link_externo_full:before {
  content: '\eac9';
}
.icon-itaufonts_desafios1:before {
  content: '\eab8';
}
.icon-itaufonts_senha_stroke:before {
  content: '\e9cb';
}
.icon-itaufonts_check_inst:before {
  content: '\ead1';
}
.icon-itaufonts_check:before {
  content: '\e927';
}
.icon-itaufonts_chat_entregue:before {
  content: '\ea7f';
}
.icon-itaufonts_chat_enviado:before {
  content: '\ea80';
}
.icon-itaufonts_cadastro_positivo:before {
  content: '\eab3';
}
.icon-itaufonts_recarga:before {
  content: '\eab9';
}
.icon-itaufonts_add:before {
  content: '\ead3';
}
.icon-itaufonts_outline_swipe:before {
  content: '\eab0';
}
.icon-itaufonts_calculadora:before {
  content: '\e91d';
}
.icon-itaufonts_mais:before {
  content: '\e973';
}
.icon-itaufonts_fechar:before {
  content: '\e956';
}
.icon-itaufonts_juros_descontos:before {
  content: '\e96d';
}
.icon-itaufonts_menu_hamburguer:before {
  content: '\ea76';
}
.icon-itaufonts_cifrao:before {
  content: '\e9b2';
}
.icon-itaufonts_euro:before {
  content: '\eab7';
}
.icon-itaufonts_arroba:before {
  content: '\e911';
}
.icon-itaufonts_e_comercial:before {
  content: '\e948';
}
.icon-itaufonts_outros:before {
  content: '\e97d';
}
.icon-itaufonts_conexao:before {
  content: '\e92e';
}
.icon-itaufonts_qrcode:before {
  content: '\eacb';
}
.icon-itaufonts_nfc:before {
  content: '\ead4';
}
.icon-itaufonts_fingerprint:before {
  content: '\ead5';
}
.icon-itaufonts_faceid:before {
  content: '\ead8';
}
.icon-itaufonts_assessoria:before {
  content: '\e912';
}
.icon-itaufonts_tranferencias:before {
  content: '\e9bb';
}
.icon-itaufonts_pagamentos_contas:before {
  content: '\e97e';
}
.icon-itaufonts_leitor_cod_barras:before {
  content: '\e96f';
}
.icon-itaufonts_cobranca_ativa:before {
  content: '\e929';
}
.icon-itaufonts_outline_ordenar:before {
  content: '\ea99';
}
.icon-itaufonts_outline_ordenar_crescente:before {
  content: '\ea87';
}
.icon-itaufonts_outline_ordenar_decrescente:before {
  content: '\eab4';
}
.icon-itaufonts_reload:before {
  content: '\e992';
}
.icon-sair:before {
  content: '\eaab';
}
.icon-itaufonts_resgate:before {
  content: '\e994';
}
.icon-itaufonts_aplicacao:before {
  content: '\e90c';
}
.icon-itaufonts_deposito:before {
  content: '\e941';
}
.icon-itaufonts_saque:before {
  content: '\e99c';
}
.icon-itaufonts_sair_do_vermelho:before {
  content: '\e998';
}
.icon-itaufonts_seta_achatada_up:before {
  content: '\ea77';
}
.icon-itaufonts_seta_achatada:before {
  content: '\ea75';
}
.icon-itaufonts_seta:before {
  content: '\e9b1';
}
.icon-itaufonts_seta_down:before {
  content: '\e9cc';
}
.icon-itaufonts_seta_right:before {
  content: '\e9cd';
}
.icon-itaufonts_seta_up:before {
  content: '\e9ce';
}
.icon-itaufonts_full_itau_30_horas:before {
  content: '\ea1f';
}
.icon-itaufonts_itau_30_horas:before {
  content: '\e968';
}
.icon-itaufonts_iso:before {
  content: '\e967';
}
.icon-itaufonts_ir:before {
  content: '\e966';
}
.icon-itaufonts_inss:before {
  content: '\e961';
}
.icon-itaufonts_5_servicos:before {
  content: '\e900';
}
.icon-itaufonts_twitter:before {
  content: '\e9be';
}
.icon-itaufonts_full_twitter:before {
  content: '\ea67';
}
.icon-itaufonts_instagram:before {
  content: '\e962';
}
.icon-itaufonts_full_instagram:before {
  content: '\ea1c';
}
.icon-itaufonts_facebook:before {
  content: '\e954';
}
.icon-itaufonts_full_facebook:before {
  content: '\ea11';
}
