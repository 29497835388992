.Dropdown {
  width: 100%;
  i {
    font-size: 25px;
  }
  .collapse-content-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .collapse-button-no-click{
    cursor: initial !important;
  }
  .collapse-button {
    width: 100%;
    background: none;
    border: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    .button-left-content-wrapper{
      display: flex;

      .button-icon{
        display: block;
        padding-right: 16px;
        margin: auto;
      }
      .button-label{
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button-title{
          margin: 0px;
          font-family: 'ItauText-Bold';
          font-size: 16px;
          line-height: 25px;
        }
        .button-subtitle{
          margin: 0px;
          font-size: 16px;
        }
      }
    }
    .arrow-icon{
      display: block;
      margin: auto 0;
    }
  }
}
